<div class="d-flex">
  <a class="sm-back-btn" [routerLink]="breadcrumbs[0].url">
    <img src="assets/images/icons/arrow-left.svg" /> Back
  </a>
  <nav style="--bs-breadcrumb-divider: url(assets/images/icons/arrow-right.svg);" aria-label="breadcrumb">
    <ol class="breadcrumb">
      @for (item of breadcrumbs; track $index) {
        <li class="breadcrumb-item" [class.active]="$index == breadcrumbs.length-1" [title]="item.label | translate">
          @if(item.url){
            <a [routerLink]="item.url">{{item.label | translate}}</a>
          }@else {
            {{ item.label | truncate: 60 | translate }}
          }
        </li>
      }
    </ol>
  </nav>
</div>
